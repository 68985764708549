import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Button, Figure} from "react-bootstrap";

import ExecutiveLead from "../assets/images/lead.png";
import CalendlyLogo from "../assets/images/calendly-blue.png";


const ComponentName = () => {
    
    const data = useStaticQuery(query);
    const {allContentfulOurPortfolioBox:{
        nodes:vb
    }} = data
    


return (
    <div className="video-box">
        <div className="video-box-container"> 
            <p className="title">Ready to talk!</p>
            <p className="text">Set up a meeting through Calendly</p>
            <div className="box-img">
                <img src={ExecutiveLead} alt="executive lead" />
                <div>
                    <h3 className="name">Lorik Mullaademi</h3>
                    <p className="position">Executive Lead</p>
                </div>
            </div>
            <a className="primary-btn-s meet-sogody" href="https://calendly.com/lorikmullaademi/15-minute-meeting" target="_blank">Set up a meeting <img src={CalendlyLogo} alt="calendly logo" className="calendly-img" /></a>
        </div>
    </div>
)
}

export const query = graphql`
{
    allContentfulOurPortfolioBox(limit: 1, filter: {node_locale: {eq: "en-US"}}) {
        nodes {
        btnTitle
        title
        image {
            fluid(quality: 100) {
            src
            }
        }
        }
    }
}
`

export default ComponentName

